<template>
  <div class="user">
    <div class="user-sculpture">
      <div class="user-sculpture_l">{{ userInfo.head }}</div>
      <div class="user-sculpture_r">
        <div class="user-sculpture_name">{{ userInfo.departmentName }}</div>
        <div class="user-sculpture_describe">{{ userInfo.nickname }}</div>
      </div>
    </div>
<!--    <div class="about">-->
<!--      <span class="icon icon-gantanhao"></span>-->
<!--      关于工单-->
<!--    </div>-->
    <router-link to="/updatePassWord" class="about">
      <span class="icon icon-24px"></span>
      修改密码
    </router-link>
<!--    <div class="about">-->
<!--      <span class="icon icon-right-circle-fill"></span>-->
<!--      配置向导-->
<!--    </div>-->
    <div class="sign_to" @click="tologin">
      退出登录
    </div>
  </div>
</template>
<script>
import {ChangePageTitle} from '../../common/js/upTitle'
import {getUserInfo} from '@/utils/auth.js'
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      userInfo: []
    }
  },
  created() {
    ChangePageTitle('我的');
    this.getUserInfo();
  },
  methods: {
    ...mapActions(['user/bowOut']),
    tologin() {
      this['user/bowOut']();
      this.$router.push('/login')
    },
    getUserInfo() {
      var userInfo = getUserInfo();
      userInfo.head = userInfo.departmentName.slice(0, 1);
      this.userInfo = userInfo;
    }
  }
}
</script>
<style lang="less" scoped>
.user {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .user-sculpture {
    height: 300px;
    width: 95%;
    margin: auto;
    margin-top: 40px;
    padding: 16px 20px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .user-sculpture_l {
      width: 100px;
      height: 100px;
      text-align: center;
      background-color: #6dcf85;
      border-radius: 50%;
      color: #fff;
      font-size: 40px;
      line-height: 100px;
    }

    .user-sculpture_r {
      flex: 1;
      margin-left: 40px;

      .user-sculpture_name {
        color: black;
        font-size: 40px;
        font-weight: 600;
      }

      .user-sculpture_describe {
        margin-top: 40px;
        color: #999;
        font-size: 30px;
      }
    }
  }

  .about {
    width: 95%;
    margin: auto;
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: black;
    font-size: 30px;

    .icon {
      margin-right: 16px;
      font-size: 32px;
      color: #999;
    }
  }

  .sign_to {
    width: 95%;
    margin: auto;
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 10px;
    color: #999;
    font-weight: 700;
    font-size: 30px;
    margin-top: 40px;
    text-align: center;
  }
}
</style>
